import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { useLocation } from "react-router-dom";

// images

export default function Header() {
  const location = useLocation();

  const [nav, setNav] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const handleClickScroll = (e) => {
    e.preventdefault();
    const element = document.getElementById('Home', 'Contactus');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <section className={scroll ? "header-main  fixed-header" : "header-main"}>
        <Container>
          <div className="header">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <Link to="/" class="navbar-brand">
                  <img src="images/logo.png" alt="logo" className="img-fluid" />
                </Link>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setNav(!nav)}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </button>
                <div
                  class={
                    nav
                      ? "collapse navbar-collapse show"
                      : "collapse navbar-collapse"
                  }
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav scroll-menu ms-auto mb-2 mb-lg-0">
                    <Button
                      onClick={() => setNav(false)}
                      className="close-menu"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gg"
                        width="28"
                        height="28"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M6.225 4.811a1 1 0 0 0-1.414 1.414L10.586 12L4.81 17.775a1 1 0 1 0 1.414 1.414L12 13.414l5.775 5.775a1 1 0 0 0 1.414-1.414L13.414 12l5.775-5.775a1 1 0 0 0-1.414-1.414L12 10.586L6.225 4.81Z"
                        />
                      </svg>
                    </Button>
                    {location?.pathname !== "/page"
                      ?
                      <>
                        <li class="nav-item">
                          <a
                            href="#Home"
                            class="nav-link gradient-text"
                            aria-current="page"

                          >
                            Home
                          </a>
                        </li>

                        <li class="nav-item">
                          <a href="#Contactus" class="nav-link gradient-text">
                            Contact Us
                          </a>
                        </li>
                        <li class="nav-item">
                          <a href="#Aboutus" class="nav-link gradient-text">
                            About Us
                          </a>
                        </li>
                      </>
                      : null}
                  </ul>
                </div>
              </div>
            </nav>

            <div className="add-wallet-wrap">
              {/* <div className="wallet-section">
                
                <Link to="/" className="nav-link sigb-up-link">
                  Buy SETVR 
                </Link>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

import React, { useContext, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import MyRouts from "./Routers/routes";
import { SettingsContext } from "context/SettingsProvider";
import { getSettings } from "helpers/backend_helper";


function App() {
  const [, setSettings] = useContext(SettingsContext);

  useEffect(async () => {
    try {
      const data = await getSettings();
      setSettings(data);
      console.log("settings", data)
    } catch (e) {
      console.log("e", e)
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <MyRouts />
    </div>
  );
}

export default App;

import { SettingsContext } from "context/SettingsProvider";
import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Footer() {
  const [settings] = useContext(SettingsContext);

  return (
    <>
      <section className="footer-main position-relative">
        <Container className="p-0">
          <div className="social_icon_bar border-bottom mb-3">
            <div className="d-flex align-items-center gap-3">
              <div className="social_bar">
                <ul>
                  <li>
                    <a href={settings?.socialMediaSetting?.facebook || "javasscript:void(0)"} target="_blank"
                      onClick={(e) => {
                        if (!settings?.socialMediaSetting?.facebook) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z"
                          fill="#191919"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href={settings?.socialMediaSetting?.twitter || "javasscript:void(0)"} target="_blank"
                      onClick={(e) => {
                        if (!settings?.socialMediaSetting?.twitter) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z"
                          fill="#191919"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href={settings?.socialMediaSetting?.linkedin || "javasscript:void(0)"} target="_blank"
                      onClick={(e) => {
                        if (!settings?.socialMediaSetting?.linkedin) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6.94043 5.00002C6.94017 5.53046 6.7292 6.03906 6.35394 6.41394C5.97868 6.78883 5.46986 6.99929 4.93943 6.99902C4.409 6.99876 3.90039 6.78779 3.52551 6.41253C3.15062 6.03727 2.94016 5.52846 2.94043 4.99802C2.9407 4.46759 3.15166 3.95899 3.52692 3.5841C3.90218 3.20922 4.411 2.99876 4.94143 2.99902C5.47186 2.99929 5.98047 3.21026 6.35535 3.58552C6.73024 3.96078 6.9407 4.46959 6.94043 5.00002ZM7.00043 8.48002H3.00043V21H7.00043V8.48002ZM13.3204 8.48002H9.34043V21H13.2804V14.43C13.2804 10.77 18.0504 10.43 18.0504 14.43V21H22.0004V13.07C22.0004 6.90002 14.9404 7.13002 13.2804 10.16L13.3204 8.48002Z"
                          fill="#191919"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="wallet-section">
                <Link to="/" className="nav-link sigb-up-link">
                  Available Soon!
                </Link>
              </div> */}
            </div>

            <div className="policy_side">
              <ul>
                <li>
                  <a href="/page?type=terms-of-services">Terms of Service</a>
                </li>
                <li>
                  <div className="dot"></div>
                </li>
                <li>
                  <a href="/page?type=privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-copyright text-center">
            <p>{settings?.reservedRights || "© 2020-2021 All Rights Reserved"} </p>
          </div>
        </Container>
      </section>
    </>
  );
}

import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";


const defaultData = {
  leftDescription: ` Professor Gary Lee has said "The memories of our past sport
  martial arts leaders are priceless" and that "a home for their
  memories" is needed. Untiring and unyielding, Professor Lee
  has built the Sport Karate Museum with the vision of a story
  that unfolds before the eyes telling an authentic history of
  sport martial arts and its relics, uniforms, belts, and
  documents. Gary has taken the gift of life and through his
  great character, dedication, and ethical standards built a
  wonderful educational institution that caresses the entire
  legendary history of sport martial arts helping to turn local,
  national, and international legends into eternal legends
  through the Sports Karate Museum's various platforms such as
  its website, annual events, and soon a virtual history portal
  inside of SETVR!`,
  bottomLeftHeading: "Sport Karate Museum",
  backgroundImage: "",
  rightForgroundImage: "/images/setvrsport.png",
  visitLink: "https://sportkaratemuseumarchives.com/"
};

const Museum = () => {
  const [sectionEight, setSectionEight] = useState(defaultData);

  const [settings] = useContext(SettingsContext);
  useEffect(() => {
    if (settings?.sectionEight) {
      const data = {
        leftDescription: settings?.sectionEight?.leftDescription || defaultData.leftDescription,
        bottomLeftHeading: settings?.sectionEight?.bottomLeftHeading || defaultData.bottomLeftHeading,
        rightForgroundImage: settings?.sectionEight?.rightForgroundImage || defaultData?.rightForgroundImage,
        backgroundImage: settings?.sectionEight?.backgroundImage || defaultData?.backgroundImage,
        visitLink: settings?.sectionEight?.visitLink || defaultData?.visitLink
      }
      setSectionEight(data);
    }
  }, [settings])


  return (
    <Fade>
      <section className="Museum_sec comon_pad mt-4 position-relative">
        <img src="/images/Pin.png" alt="" className="pinone img-fluid" />
        <img src="/images/Pin.png" alt="" className="pintwo img-fluid" />
        <div className="arrow_shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
          >
            <path
              d="M120 1.04907e-05L0 0L-1.04907e-05 120L120 1.04907e-05Z"
              fill="#F9F9F9"
            />
          </svg>
        </div>
        <Container>
          <Row>
            <Col lg={7} md={6} sm={12}>
              <div className="museum_left">
                <p>
                  {sectionEight?.leftDescription}
                </p>

                <div className="sport_krate position-relative">
                  <h1>
                    <span>
                      {sectionEight?.bottomLeftHeading?.slice(0, 12)} <br></br>{" "}
                    </span>{" "}
                    {sectionEight?.bottomLeftHeading?.slice(12)}
                  </h1>

                  <div className="visit_wrap ">
                    <a href={sectionEight?.visitLink} target="_blank" className="btn-common visit_btn">
                      Visit the Website
                    </a>

                    {/* <div className="coming_soon">
                    <h2>Coming Soon</h2>
                    </div> */}
                  </div>

                </div>
              </div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <div className="sport_img position-relative">
                <div className="banner_poster position-relative">
                  <img src={sectionEight?.rightForgroundImage} alt="" className="img-fluid" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Museum;

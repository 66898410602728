import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";

const defaultData = {
  heading: "Bring fun structured practice into your daily life",
  subHeading: "/images/blue-bedge.jpeg",
  backgroundImage: "",
  forgroundImages: [{
    name: "Virtual Dojo",
    title: "Warm Up",
    image: "/images/funboxone.png"
  },
  {
    name: "Virtual Attackers",
    title: "Fight",
    image: "/images/funboxtwo.png"
  },
  {
    name: "Yoga and more",
    title: "Warm Up",
    image: "/images/funboxthree.png"
  }
  ]
};

const Funstructure = () => {
  const [sectionThree, setSectionThree] = useState(defaultData);


  const [settings] = useContext(SettingsContext);
  useEffect(() => {
    if (settings?.sectionThree) {
      const data = {
        heading: settings?.sectionThree?.heading || defaultData?.heading,
        subHeading: settings?.sectionThree?.subHeading || defaultData?.subHeading,
        backgroundImage: settings?.sectionThree?.backgroundImage || defaultData?.backgroundImage,
        forgroundImages: settings?.sectionThree?.forgroundImages?.length ? settings?.sectionThree?.forgroundImages : defaultData?.forgroundImages
      };
      setSectionThree(data);
    }
  }, [settings])


  return (
    <Fade>
      <section className="Funstructure_col comon_pad">
        <Container>
          <div className="funtstructure_head text-center">
            <h2>{sectionThree?.heading}</h2>
            <div className="img-coverd">
              <img src={sectionThree?.subHeading} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="funstructure_boxes mt-4">
            <Row>
              {sectionThree?.forgroundImages?.map((item) => (
                <Col lg={4} md={6} sm={12}>
                  <div className="box_common">
                    <div className="funbox_img mb-2">
                      <img src={item?.image} alt="" className="img-fluid" />
                    </div>
                    <div className="funbox_cont">
                      <h4>{item?.title}</h4>
                      <p>{item?.name}</p>
                    </div>
                  </div>
                </Col>
              ))
              }
            </Row>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Funstructure;

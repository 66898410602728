import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";



const defaultData = {
  rightHeading: "About SET VR",
  rightDescription: `SetVR was created by a team of three people made up of a doctor, an engineer, and
  a teacher, all blackbelts, with a combined experience of 130
  years in martial arts and self-defense training.`,
  bottomRightSubHeading: "/images/blue-bedge.jpeg",
  backgroundImage: "",
  leftForgroundImage: "/images/logo.png"
};

const Aboutsetvr = () => {
  const [sectionSix, setSectionSix] = useState(defaultData);

  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionSix) {
      const data = {
        rightHeading: settings?.sectionSix?.rightHeading || defaultData.rightHeading,
        rightDescription: settings?.sectionSix?.rightDescription || defaultData.rightDescription,
        bottomRightSubHeading: settings?.sectionSix?.bottomRightSubHeading || defaultData?.bottomRightSubHeading,
        backgroundImage: settings?.sectionSix?.backgroundImage || defaultData?.backgroundImage,
        leftForgroundImage: settings?.sectionSix?.leftForgroundImage || defaultData?.leftForgroundImage
      }
      setSectionSix(data);
    }
  }, [settings])


  return (
    <Fade>
      <section id="Aboutus" className="Aboutsetvr comon_pad">
        <Container>
          <div className="inner_About">
            <Row>
              <Col lg={5} md={6} sm={12}>
                <div className="about_left_img">
                  <img
                    src={sectionSix?.leftForgroundImage}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col lg={7} md={6} sm={12}>
                <div className="about_right_content">
                  <h2>{sectionSix?.rightHeading}</h2>
                  <p>
                    {sectionSix?.rightDescription}
                  </p>

                  <div className="img-coverd3">
                    <img src={sectionSix?.bottomRightSubHeading} alt="" className="img-fluid" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Aboutsetvr;

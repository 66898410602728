import React from "react";
import Header from "../Component/Header/Header";
import Footer from "Component/Footer/Footer";
import ContentPage from "Pages/Content";


export default function Contenttheme() {

    return (
        <>
            <Header />
            <ContentPage />
            <Footer />
        </>
    );
}
